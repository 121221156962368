<template>
  <div>
    <CRow class="mt-3">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="dataTableId"
              ref="dataTableRef"
              class="zq--table"
              :items="instantWinTileData"
              :fields="tableFields"
              :responsive="true"
              :border="false"
            >
              <template #col="{item}">
                <td>{{ item.location.col }}</td>
              </template>
              <template #row="{item}">
                <td>{{ item.location.row }}</td>
              </template>
              <template #contraints="{item}">
                <td>{{ item.contraints }}</td>
              </template>
              <template #probability="{item}">
                <td>{{ item.probability ? item.probability : 'Auto' }}</td>
              </template>
              <template #reward="{item}">
                <td>
                  <div class="reward" v-if="item.reward && Object.keys(item.reward).length !== 0">
                    <div class="reward-data">
                      <span v-if="item.reward['name']">Name: {{ item.reward.name }}</span>
                      <span v-if="item.reward['rewardValue']">Value: {{ item.reward.rewardValue }}</span>
                      <span v-if="item.reward['rewardType']">Type: {{ item.reward.rewardType.key }}</span>
                    </div>
                  </div>
                </td>
              </template>
              <template #text="{item}">
                <td>{{ truncateAndClearText(item.text) }}</td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'instantWinTileView',
  props: {
    instantWinTileData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      tableFields: ['col', 'row', 'contraints', 'probability', 'reward', 'text'],
    };
  },
  methods: {
    truncateAndClearText(text) {
      text = text.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ').trim();
      let sliced = text.slice(0, 100);
      if (sliced.length < text.length) {
        sliced += '...';
      }

      return sliced;
    },
  }
};
</script>


<style scoped lang="scss">
.reward {
  display: flex;
  justify-content: center;

  .reward-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>